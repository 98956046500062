.container {
    align-content: center;
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
    margin: auto;
}

.page {
    padding: 2em;
}

.title {
    font-size: 6vh;
    margin-right: 0;
    margin-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 2em;
    // text-align: center;
    word-wrap: break-word;
}

.subtitle {
  font-size: 4vh;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 1em;
  // text-align: center;
  word-wrap: break-word;
}

.subsubtitle {
  font-size: 3vh;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 3em;
  // text-align: center;
  word-wrap: break-word;
}

.image {
  width: 100%;
}